





































































































































































import { Component, Vue } from 'vue-property-decorator';
import BTablePag from '@/modules/program-monitor2/components/b-table-pag.vue';
import store from '@/services/store';

interface ITotalRow {
    abp: number;
    prog: number;
    indicator: number;
    sgp: number;
    year1: number;
    year2: number;
    year3: number;
}

interface IParams {
    year: number,
    region: string,
    abp: null,
    prg: null,
    intercon: {},
    regionsCode: string
}

@Component({
    name: 'c-inter-bp-forecast',
    components: {
        'b-table-pag': BTablePag
    }
})
export default class CInterconBpForecast extends Vue {
    public pgRows = 0;
    public curPage = 1;
    public perPage = 10000;

    private totalRow: ITotalRow = { abp: 0, prog: 0, indicator: 0, sgp: 0, year1: 0, year2: 0, year3: 0 };
    private params: IParams = { year: 0, region: '', abp: null, prg: null, intercon: 0, regionsCode: '' };
    private periodLst: any[] = [];

    private budgetLevel: number[] = [];
    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    private region = '450101';

    private tableData: any = [];

    private regionCode = '';

    private padLeadingZeros(num: string, size: number) {    // -------добавление нулей после значения в зависимости от размера значения
        let s = String(num);
        while (s.length < size) { s = s + '0'; }
        return s;
    }

    private get dataToPg() {
        /* this.pgRows = this.filteredData.length;
        const begIndx = (this.curPage - 1) * (this.perPage + 1);
        const endIndx = begIndx + this.perPage;
        if (endIndx >= this.pgRows) {
            return this.filteredData.slice(begIndx);
        }
        return this.filteredData.slice(begIndx, endIndx);*/
        return this.filteredData;
    }

    private get filteredData() {
        this.params.intercon = this.curIntercon;
        if (this.curIntercon.val === 0) {
            this.pgRows = this.tableData.length;
            this.setTotalRow(this.tableData);
            return this.tableData;
        }
        const result = this.tableData.map((itemAbp: any) => {
            const prgRes = itemAbp.prgLst.filter((itemPrg: any) => {
                if (this.curIntercon.val === 1) { // с взаимоувязкой
                    return itemPrg.indicator && itemPrg.sgp;
                }
                return !itemPrg.indicator && !itemPrg.sgp;
            });
            if (prgRes.length > 0) {
                const resultAbp = Object.assign({}, itemAbp);
                let field = 'allYear';
                switch (this.curIntercon.val) {
                    case 1:
                        field = 'withYear';
                        break;
                    case 2:
                        field = 'withOutYear';
                        break;
                    default:
                        field = 'allYear';
                }
                resultAbp.year1 = resultAbp[field].year1;
                resultAbp.year2 = resultAbp[field].year2;
                resultAbp.year3 = resultAbp[field].year3;
                resultAbp.prgLst = prgRes;
                return resultAbp;
            }
            return null;
        }).filter((item: any) => { return item; });
        this.setTotalRow(result);
        // console.log('result: ' + JSON.stringify(result));
        return result;
    }

    private collapseArr: boolean[] = [];
    private collapseAll = true;

    private curYear: any | null = null;
    private curPeriod: any | null = null;

    private dicts = {
        abpLst: { url: '/api-py/get-dict-ved-abp-by-budgetlevel/', items: [] },
        ebkFunc: { url: '/api/forecast/dict-ebk-func-prg', items: [] }
    };
    // ---АБП
    private curAbp: any | null = { name: 'Все', abp: null };

    private progress = 0;

    private get abpDict() {
        const result: any = [{ name: 'Все', abp: null }];
        for (const el of this.dicts.abpLst.items) {
            result.push(this.setNameLang(el, 'abp'));
        }
        return result;
    }

    // ------программы
    private get ebkDict() { // ebk
        const result: any = [];
        for (const el of this.dicts.ebkFunc.items) {
            result.push(this.setNameLang(el, 'prg'));
        }
        return result;
    }
    private curProg: any = { name: 'Все', prg: null };

    // -----взаимоувязка
    private curIntercon = { name: 'Все', val: 0 };
    private interconLst = [{ name: 'Все', val: 0 }, { name: 'С взаимоувязкой', val: 1 }, { name: 'Без взаимоувязки', val: 2 }];

    private get progDict() {
        if (this.curAbp === null) { return []; }
        let fl = false;
        const result = this.ebkDict.filter((item: any) => {
            if (this.curAbp.abp === item.abp && this.curProg !== null && item.id === this.curProg.id) { fl = true; }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return this.curAbp.abp === item.abp;
        });
        if (!fl) {
            if (this.curProg.prg) {
                this.curProg = { name: 'Все', prg: null };
            }
        }
        result.unshift({ name: 'Все', prg: null });
        return result;
    }

    private async load() {
        this.tableData = [];
        if (this.curYear === null) { return; }
        this.totalRow = { abp: 0, prog: 0, indicator: 0, sgp: 0, year1: 0, year2: 0, year3: 0 };
        this.progress = 5;
        this.collapseAll = true;
        let response: any = null;
        const regionsCode = this.padLeadingZeros(this.regionCode, 9);
        try {
            const params = { year: this.curYear, region: this.region, abp: null, prg: null };
            if (this.curAbp) {
                params.abp = this.curAbp.abp;
                if (this.curAbp.abp !== null && this.curProg) { params.prg = this.curProg.prg; }
            }

            this.params.year = params.year;
            this.params.region = params.region;
            this.params.abp = params.abp;
            this.params.prg = params.prg;
            this.params.intercon = 0;
            this.params.regionsCode = regionsCode

//------------------------------------------Изменен сервис------------------------------------------------------------//
            response = await fetch('/api-py/budget-cost-and-variants-additional/' + encodeURI(JSON.stringify(params)));
            if (response.status === 200) {
                response = await response.json();
                // console.log('response: ' + JSON.stringify(response));
            } else {
                this.makeToast('danger', 'Загрузка', `${response.status} - ${response.statusText}`);
                response = null;
                this.progress = 0;
                return;
            }
            // console.log('params: ' + JSON.stringify(this.params));
            // console.log('this.dataToPg: ' + this.dataToPg.length);
            // this.$emit('listBudgetDocuments', this.params);
//--------------------------------------------------------------------------------------------------------------------//
            // response = await fetch('/api-py/budget-intercon-bp/' + encodeURI(JSON.stringify(params)));
            // if (response.status === 200) {
            //     response = await response.json();
            // } else {
            //     this.makeToast('danger', 'Загрузка', `${response.status} - ${response.statusText}`);
            //     response = null;
            //     this.progress = 0;
            //     return;
            // }
//--------------------------
        } catch (error) {
            response = null;
            this.makeToast('danger', 'Ошибка загрузки', (error as Error).toString());
            this.progress = 0;
            return;
        }
        this.progress = 70;
        if (response === null) { return; }
        // let budgetData: any[] = [];
        let mp: any = new Map();
        mp = this.getTreeMap(response.items);
        // ---сгп
        response = null;
        try {
            // let prog = '';
            let abp = '';
            if (this.curAbp !== null && this.curAbp.abp !== null) { abp = this.curAbp.abp; }
            // if (this.curProg !== null && this.curProg.prg !== null) { prog = this.curProg.prg; }
//------------------------------------------Изменен сервис------------------------------------------------------------//
            // console.log('regionsCode: ' + regionsCode);
            const url = `/api/stat-dicts/all_sgp_for_budget_programs?year=${this.curYear}&program=&goal=&abp=${abp}&codeKato=${regionsCode}`;
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                if (this.curProg !== null && this.curProg.prg !== null) {
                    for (const el of response) {
                        el.abps = el.abps.filter((item: any) => {
                            return item.prg === this.curProg.prg;
                        });
                    }
                }
            } else {
                this.makeToast('danger', 'Загрузка /forecast/sgp', `${response.status} - ${response.statusText}`);
                response = null;
                this.progress = 0;
                return;
            }
//--------------------------------------------------------------------------------------------------------------------//
//             response = await fetch(`/api/forecast/sgp?year=${this.curYear}&program=&goal=&abp=${abp}`);
//             if (response.status === 200) {
//                 response = await response.json();
//                 if (this.curProg !== null && this.curProg.prg !== null) {
//                     for (const el of response) {
//                         el.abps = el.abps.filter((item: any) => {
//                             return item.prg === this.curProg.prg;
//                         });
//                     }
//                 }
//             } else {
//                 this.makeToast('danger', 'Загрузка /forecast/sgp', `${response.status} - ${response.statusText}`);
//                 response = null;
//                 this.progress = 0;
//                 return;
//             }
//--------------------------
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки /forecast/sgp', (error as Error).toString());
            this.progress = 0;
            return;
        }
        this.progress = 90;
        for (const el of response) {
            if (el.abps !== null) {
                for (const abps of el.abps) {
                    const abpMp = mp.get(abps.abp);
                    if (abpMp !== undefined) {
                        const prgMp = abpMp.get(abps.prg);
                        if (prgMp !== undefined) {
                            if (prgMp.targetIndic === undefined) { prgMp.targetIndic = []; }
                            prgMp.targetIndic.push({ indicator: el.indicator, sgp: el.program });
                            abpMp.set(abps.prg, prgMp);
                            mp.set(abps.abp, abpMp);
                        }
                    }
                }
            }
        }
        // -- разворачивание в массив
        const abpArr: any[] = [];
        this.collapseArr = [];
        for (const [keyAbp, valueAbp] of mp) {
            const objAbp: any = { name: null, abp: keyAbp, year1: 0, year2: 0, year3: 0, prgLst: [], allYear: { year1: 0, year2: 0, year3: 0 }, withYear: { year1: 0, year2: 0, year3: 0 }, withOutYear: { year1: 0, year2: 0, year3: 0 } }; // ВЗаимоувязка allYear- Все, withYear: c взаимоувязкой, withOutYear - без взаимоувязки
            const prgArr: any[] = [];
            for (const [keyPrg, valuePrg] of valueAbp) {
                const objPrg = { name: valuePrg.prgNameRu, prg: keyPrg, year1: valuePrg.year1, year2: valuePrg.year2, year3: valuePrg.year3 };
                if (objAbp.name === null) { objAbp.name = valuePrg.abpNameRu; }
                if (valuePrg.year1 !== null) { objAbp.year1 += valuePrg.year1; }
                if (valuePrg.year2 !== null) { objAbp.year2 += valuePrg.year2; }
                if (valuePrg.year3 !== null) { objAbp.year3 += valuePrg.year3; }
                if (valuePrg.year1 !== null) { objAbp.allYear.year1 += valuePrg.year1; }
                if (valuePrg.year2 !== null) { objAbp.allYear.year2 += valuePrg.year2; }
                if (valuePrg.year3 !== null) { objAbp.allYear.year3 += valuePrg.year3; }
                if (valuePrg.targetIndic && valuePrg.targetIndic.length > 0) {
                    for (const ti of valuePrg.targetIndic) {
                        prgArr.push(Object.assign(ti, objPrg));
                    }
                    if (valuePrg.year1 !== null) { objAbp.withYear.year1 += valuePrg.year1; }
                    if (valuePrg.year2 !== null) { objAbp.withYear.year2 += valuePrg.year2; }
                    if (valuePrg.year3 !== null) { objAbp.withYear.year3 += valuePrg.year3; }
                } else {
                    if (valuePrg.year1 !== null) { objAbp.withOutYear.year1 += valuePrg.year1; }
                    if (valuePrg.year2 !== null) { objAbp.withOutYear.year2 += valuePrg.year2; }
                    if (valuePrg.year3 !== null) { objAbp.withOutYear.year3 += valuePrg.year3; }
                    prgArr.push(Object.assign({ indicator: null, sgp: null }, objPrg));
                }
            }
            objAbp.year1 = Math.round(objAbp.year1);
            objAbp.year2 = Math.round(objAbp.year2);
            objAbp.year3 = Math.round(objAbp.year3);
            prgArr.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
            objAbp.prgLst = prgArr;
            abpArr.push(objAbp);
            this.collapseArr.push(true);
        }
        abpArr.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.tableData = abpArr;
        // console.log('this.tableData: ' + JSON.stringify(this.tableData));

        // this.makeToast('success', 'Загрузка', 'Данные загружены');
        // console.log('this.dataToPg: ' + this.dataToPg.length);
        this.$emit('listBudgetDocuments', this.params, this.dataToPg.length);
        this.progress = 100;
    }

    private setTotalRow(arr: any[]) { // расчет totalRow
        const indicator: any[] = [];
        this.totalRow = { abp: 0, prog: 0, indicator: 0, sgp: 0, year1: 0, year2: 0, year3: 0 };
        const sgp: any[] = [];
        for (const el of arr) {
            this.totalRow.year1 += el.year1;
            this.totalRow.year2 += el.year2;
            this.totalRow.year3 += el.year3;
            const prog: any[] = [];
            for (const prg of el.prgLst) {
                if (prg.indicator) {
                    let flIndic = false;
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < indicator.length; i++) {
                        if (indicator[i] === prg.indicator.code) {
                            flIndic = true;
                            break;
                        }
                    }
                    if (!flIndic) { indicator.push(prg.indicator.code); }
                }
                if (prg.sgp) {
                    let flSgp = false;
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < sgp.length; i++) {
                        if (sgp[i] === prg.sgp.code) {
                            flSgp = true;
                            break;
                        }
                    }
                    if (!flSgp) { sgp.push(prg.sgp.code); }
                }
                if (prg.prg) {
                    let flPrg = false;
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < prog.length; i++) {
                        if (prog[i] === prg.prg) {
                            flPrg = true;
                            break;
                        }
                    }
                    if (!flPrg) { prog.push(prg.prg); }
                }
            }
            this.totalRow.prog += prog.length;
        }
        this.totalRow.indicator = indicator.length;
        this.totalRow.sgp = sgp.length;
        this.totalRow.abp = arr.length;
    }

    private getTreeMap(arr: any[]) {
        const mp = new Map();
        for (const el of arr) {
            const abp = mp.get(el.abp);
            if (abp !== undefined) {
                let prg = abp.get(el.prg);
                if (prg !== undefined) {
                    prg[`year${el.year - this.curYear + 1}`] = el.sum;
                } else {
                    prg = { abp: el.abp, prg: el.prg, year1: null, year2: null, year3: null, abpNameRu: el.abpNameRu, prgNameRu: el.prgNameRu };
                }
                prg[`year${el.year - this.curYear + 1}`] = el.sum;
                abp.set(el.prg, prg);
            } else {
                const prg: any = { abp: el.abp, prg: el.prg, year1: null, year2: null, year3: null, abpNameRu: el.abpNameRu, prgNameRu: el.prgNameRu };
                prg[`year${el.year - this.curYear + 1}`] = el.sum;
                mp.set(parseInt(el.abp), new Map([[el.prg, prg]]));
            }
        }
        return mp;
    }

    private async getObl() {
        try {
            await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                .then(response => response.json())
                .then(json => {
                    this.region = json.region;
                    this.regionCode = json.code;
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getObl', (error as Error).toString());
        }
    }

    private async loadYearsLst() {
        this.periodLst = [];
        this.curPeriod= null;
         try {
            const params = { region: this.region, abp: null };
            if (this.curAbp) { params.abp = this.curAbp.abp; }
            let response: any = await fetch(`/api-py/budget-intercon-bp-forecast-years/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                response = await response.json();
                for (const el of response) {
                    this.periodLst.push({name: `${el.cur_year} - ${el.cur_year+2}`, curYear: el.cur_year})
                }
                if (this.periodLst.length) {
                    this.curPeriod = this.periodLst[this.periodLst.length - 1];
                    this.chgCurPeriod();
                }
            } else {
                this.makeToast('danger', 'Ошибка запроса список периодов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса список периодов', (error as Error).toString());
        }
    }


    private async mounted() {
        this.getObl();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        await this.loadBudgetLevel();
        await this.loadDict(this.dicts.ebkFunc);
        await this.loadYearsLst();
        this.load();
        this.$watch('collapseAll', that.chgCollapseAll);
        this.$watch('curYear', that.load);
        this.$watch('curAbp', that.load);
        this.$watch('curProg', function () {
            if (that.curAbp !== null && that.curAbp.abp !== null) { that.load(); }
        });
    }

    private chgCurPeriod() {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.curYear;
        }
    }

    private async loadDict(dict: any) {
        await fetch(dict.url).then(response => response.json()).then(json => {
            dict.items = json;
        });
    }


    private setNameLang(obj: any, codeName?: any | null): any {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        const newObj = Object.assign({}, obj);
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (newObj.nameRu) { newObj.name_ru = newObj.nameRu; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (newObj.nameKk) { newObj.name_kk = newObj.nameKk; }
        if (txt === undefined) { txt = newObj.name_ru; }
        if (codeName !== undefined && codeName !== null && newObj[codeName] !== undefined) {
            txt = newObj[codeName] + ' - ' + txt;
        }
        newObj.name = txt;
        return newObj;
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private clkCollapse(indx: number) {
        const pgIndx = indx + (this.curPage - 1) * this.perPage;
        this.collapseArr[pgIndx] = !this.collapseArr[pgIndx];
        this.collapseArr.push(false);
        this.collapseArr.splice(this.collapseArr.length - 1, 1);
    }

    private chgCollapseAll() {
        for (let i = 0; i < this.collapseArr.length; i++) { this.collapseArr[i] = this.collapseAll; }
        this.collapseArr.push(false);
        this.collapseArr.splice(this.collapseArr.length - 1, 1);
    }

    private async loadBudgetLevel() {
        this.budgetLevel = [];
        let tmpUsr: any = this.usrId;
        if (!tmpUsr) {
            tmpUsr = store.state.user.sub;
        }
        let result: any = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId));
            if (result.status === 200) {
                result = await result.json();
            } else {
                this.makeToast('danger', 'Ошибка загрузки уровня бюджета', `${result.status} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
            return;
        }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) {
            this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
            this.budgetLevel.push(2); // для тестового сервера
        }
        this.dicts.abpLst.url += encodeURI(JSON.stringify(this.budgetLevel));
        this.loadDict(this.dicts.abpLst);
    }
}
