































































































import { Component, Vue } from 'vue-property-decorator';
import CInterconBpForecast from '@/modules/forecast/interconBpForecast.vue';
import CITargetIndicator from '@/modules/forecast/interconTargetIndicator.vue';
import store from '@/services/store';
import {number, string} from "@amcharts/amcharts4/core";
import { Ax } from '@/utils';

import axios from "axios";

interface BaseObject {
    mainId?: number;
    id?: number;
    name_ru?: string;
    name_kz?: string;
    name_en?: string;
    programId?: number;
    directionId?: number;
    goals?: any[];
    goalId?: number;
    indicators?: any[];
    macroindicator?: boolean;
    sgpDoc?: any;
    abp?: any[];
    units?: any;
    years?: any[];
}

@Component({
    components: {
        'c-inter-bp-forecast': CInterconBpForecast,
        'ci-target-indicator': CITargetIndicator
    }
})
export default class CInterconnection extends Vue {
    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    private headParams: any;
    private programsObj: BaseObject[] = [];
    private serviceParams: any;
    private abpObj: any;
    private budgetHeadParams: any;
    private error: any;
    private isGridEmpty: boolean | undefined;

    private async downloadAllSgpDocumentsReport() {
        // console.log('this.headParams: ' + JSON.stringify(this.headParams) + ' this.programsObj: ' + JSON.stringify(this.programsObj));
        if ((this.headParams && Object.keys(this.headParams).length !== 0) && (this.programsObj.length > 0)) {
            // const params = JSON.stringify({ filterParams: this.headParams, programs: this.programsObj, serviceParams: this.serviceParams, abp: this.abpObj });
            const params = JSON.stringify({ programs: this.programsObj, serviceParams: this.serviceParams, abp: this.abpObj });
            console.log('params: ' + JSON.stringify(params));
            const urls = '/api-py/interconnection_target_indicator/'+encodeURI(params);
            Ax(
                {
                    url: urls, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Взаимоувязка_ЦИ_и_БП_Целевые_индикаторы.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка формирования отчета', error.toString());
                }
            );
        }
    }

    private async downloadBudgetReport(){
        if (this.budgetHeadParams && Object.keys(this.budgetHeadParams).length !== 0 && this.isGridEmpty == true){
            const params = JSON.stringify(this.budgetHeadParams);
            // console.log('params: '+ JSON.stringify(params));
            const urls = '/api-py/intercon_budget_report/'+encodeURI(params);
            Ax(
                {
                    url: urls, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Взаимоувязка_ЦИ_и_БП_Бюджетные_программы.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка формирования отчета', error.toString());
                }
            );
        }
    }

    private listBudgetDocuments(params: any, dataSize: any){
        // console.log('dataSize : ' + dataSize);
        if (dataSize == 0){
            this.isGridEmpty = false;
        } else {
            this.isGridEmpty = true;
        }
        if (params && dataSize !== 0){
            // console.log('params: ', JSON.stringify(params));
            this.budgetHeadParams = params;
        }
    }

    private listSgpDocuments(mainArr: string | any[], headParams: any, serviceParams: any, abpObj: any){
        // console.log('mainArr: ' + JSON.stringify(mainArr));
        const program = [];
        // const goals = [];
        // const indicator = [];
        // const direction = [];
        // const shortDirections = [];
        if (mainArr.length > 0){
            for (const arr of mainArr) {
                // if (arr.abps.length > 0 && arr.years.length > 0 && arr.program !== null && arr.goals !== null && arr.direction !== null && arr.indicator !== null) {
                if (arr.years.length > 0 && arr.program !== null && arr.goals !== null && arr.direction !== null && arr.indicator !== null) {
                    const programObj: BaseObject = {};
                    programObj.id = arr.program.id;
                    program.push(programObj);
                    // shortDirections.push(createObject(arr, "direct"));
                    // direction.push(createObject(arr, "direction"));
                    // goals.push(createObject(arr, "goals"));
                    // indicator.push(createObject(arr, "indicator"));
                }
            }
        }
        // const onlyDirection = getOnlyDirectionObjects(direction);
        // const onlyShortDirections = getOnlyDirectionObjects(shortDirections);
        // for (const direct of onlyDirection){
        //     for (const goal of goals){
        //         if (direct.programId === goal.programId && direct.id === goal.directionId){
        //             for (const indic of indicator){
        //                 if (goal.programId === indic.programId && goal.directionId === indic.directionId && goal.id === indic.goalId){
        //                     for (const main of mainArr){
        //                         if (direct.mainId === main.id){
        //                             indic.macroindicator = main.macroindicator;
        //                             indic.units = main.units;
        //                             indic.sgpDoc = main.sgpParentDoc;
        //                             indic.abp = main.abps;
        //                             indic.years = main.years;
        //                         }
        //                     }
        //                     goal.indicators?.push(indic);
        //                 }
        //             }
        //             direct.goals?.push(goal);
        //         }
        //     }
        // }
        // console.log('direction: ' + JSON.stringify(onlyDirection));
        // this.directionsObj = onlyShortDirections;
        // this.mainObj = onlyDirection;
        this.headParams = headParams;
        this.programsObj = getOnlyObjects(program);
        this.serviceParams = serviceParams;
        this.abpObj = abpObj;

        // function createObject(arr: any, title: string){
        //     const obj: BaseObject = {};
        //     obj.mainId = arr.id;
        //
        //     // if (title === "direct"){
        //     //     obj.id = arr.direction.id;
        //     //     obj.programId = arr.program.id;
        //     // }
        //     if (title === "direction"){
        //         obj.id = arr.direction.id;
        //         obj.name_ru = arr.direction.name_ru;
        //         obj.name_kz = arr.direction.name_kz;
        //         obj.name_en = arr.direction.name_en;
        //         obj.programId = arr.program.id;
        //         obj.goals = [];
        //     }
        //     if (title === "goals"){
        //         obj.id = arr.goals.id;
        //         obj.name_ru = arr.goals.name_ru;
        //         obj.name_kz = arr.goals.name_kz;
        //         obj.name_en = arr.goals.name_en;
        //         obj.programId = arr.program.id;
        //         obj.directionId = arr.direction.id;
        //         obj.indicators = [];
        //     }
        //     if (title === "indicator"){
        //         obj.id = arr.indicator.id;
        //         obj.name_ru = arr.indicator.name_ru;
        //         obj.name_kz = arr.indicator.name_kz;
        //         obj.name_en = arr.indicator.name_en;
        //         obj.programId = arr.program.id;
        //         obj.directionId = arr.direction.id;
        //         obj.goalId = arr.goals.id;
        //         obj.macroindicator = false,
        //         obj.sgpDoc = {},
        //         obj.abp = [],
        //         obj.units = {},
        //         obj.years = []
        //     }
        //     return obj;
        // }

        // -----Функция для очистки массива от дубликатов
        function getOnlyObjects(arr: any[]) {
            const filteredObjectArr = arr.reduce((enteredArr: any[], current: { id: any; }) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                if (info_id == 25) {
                    file = 'Взаимоувязка целевых индикаторов и бюджетных программ';
                }
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
}
